/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ActivityLogEvent = {
    Created: 'created',
    Updated: 'updated',
    Closed: 'closed',
    Deleted: 'deleted',
    FcaRemap: 'fca_remap'
} as const;
export type ActivityLogEvent = typeof ActivityLogEvent[keyof typeof ActivityLogEvent];


export function ActivityLogEventFromJSON(json: any): ActivityLogEvent {
    return ActivityLogEventFromJSONTyped(json, false);
}

export function ActivityLogEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActivityLogEvent {
    return json as ActivityLogEvent;
}

export function ActivityLogEventToJSON(value?: ActivityLogEvent | null): any {
    return value as any;
}

